var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "n-page",
    [
      _c(
        "a-form",
        { attrs: { form: _vm.form, layout: "vertical" } },
        [
          _c(
            "a-row",
            { staticStyle: { "flex-direction": "row" } },
            [
              _c(
                "a-col",
                {
                  staticClass: "gx-text-center",
                  attrs: { xl: 6, lg: 10, md: 10, sm: 10, xs: 24 },
                },
                [
                  _c("a-card", { staticClass: "gx-card-widget heading" }, [
                    _c("h2", { staticClass: "gx-text-left" }, [
                      _vm._v("Edit Profile Picture"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "profile-pic-upload" },
                      [
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { lg: 24 } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "profile-upload-parent",
                                  staticStyle: { position: "relative" },
                                },
                                [
                                  _vm.profilePic
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "profile-img" },
                                          [
                                            _c("img", {
                                              staticClass: "gx-mb-3",
                                              attrs: { src: _vm.profilePic },
                                              on: { error: _vm.defaultImage },
                                            }),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm.user_image
                                    ? _c(
                                        "button",
                                        { staticClass: "edit-profile-btn" },
                                        [
                                          _c(
                                            "a-popconfirm",
                                            {
                                              staticClass: "delete-popup",
                                              attrs: {
                                                placement: "bottom",
                                                title:
                                                  "Are you sure to delete this image?",
                                                "ok-text": "Yes",
                                                "cancel-text": "No",
                                              },
                                              on: {
                                                confirm: _vm.confirm,
                                                cancel: _vm.cancel,
                                              },
                                            },
                                            [
                                              _c(
                                                "a",
                                                { attrs: { href: "#" } },
                                                [
                                                  _c("a-icon", {
                                                    attrs: { type: "delete" },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]),
                            _c(
                              "a-col",
                              { staticClass: "gx-mt-4", attrs: { lg: 24 } },
                              [
                                _c(
                                  "a-upload",
                                  {
                                    attrs: {
                                      "file-list": _vm.fileList,
                                      remove: _vm.handleRemove,
                                      "before-upload": _vm.beforeUpload,
                                      "show-upload-list": true,
                                      multiple: false,
                                    },
                                  },
                                  [
                                    _c(
                                      "a-button",
                                      { staticClass: "gx-mb-0" },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "upload" },
                                        }),
                                        _vm._v(
                                          " Select File\n                  "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "a-button",
                                  {
                                    staticStyle: { "margin-top": "16px" },
                                    attrs: {
                                      type: "primary",
                                      disabled: _vm.fileList.length === 0,
                                      loading: _vm.uploading,
                                    },
                                    on: { click: _vm.handleUpload },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.uploading
                                            ? "Uploading"
                                            : "Start Upload"
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { xl: 18, lg: 14, md: 14, sm: 14, xs: 24 } },
                [
                  _c(
                    "a-card",
                    { staticClass: "gx-card-widget heading information-card" },
                    [
                      _c(
                        "a-row",
                        { staticStyle: { "flex-direction": "row" } },
                        [
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [_c("h2", [_vm._v("Personal Information")])]
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "First Name" } },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "first_name",
                                          {
                                            rules: [
                                              {
                                                required: true,
                                                message:
                                                  "First name is required.",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                    'first_name',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'First name is required.',\n                        },\n                      ],\n                    },\n                  ]",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Last Name" } },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "last_name",
                                          {
                                            rules: [
                                              {
                                                required: true,
                                                message:
                                                  "Last name is a required field.",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                    'last_name',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Last name is a required field.',\n                        },\n                      ],\n                    },\n                  ]",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Email" } },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: [
                                          "work_email",
                                          {
                                            rules: [
                                              {
                                                required: true,
                                                message:
                                                  "Your email is required.",
                                              },
                                            ],
                                          },
                                        ],
                                        expression:
                                          "[\n                    'work_email',\n                    {\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Your email is required.',\n                        },\n                      ],\n                    },\n                  ]",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 10, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Phone Number" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "custom-phone" },
                                    [
                                      _c("vue-phone-number-input", {
                                        staticClass: "phone_input",
                                        attrs: {
                                          color: "#d9d9d9",
                                          "valid-color": "#d9d9d9",
                                          "error-color": "#d9d9d9",
                                          "default-country-code": _vm.phoneIso,
                                          "preferred-countries":
                                            _vm.preferredCountries,
                                          "all-letters-characters": true,
                                        },
                                        on: { update: _vm.onCountrySelect },
                                        model: {
                                          value: _vm.phone,
                                          callback: function ($$v) {
                                            _vm.phone = $$v
                                          },
                                          expression: "phone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  !_vm.validPhone
                                    ? _c("div", { staticClass: "has-error" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ant-form-explain text-left",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(_vm.invalidPhoneMsg) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Date of Birth" } },
                                [
                                  _c("a-date-picker", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["dob"],
                                        expression: "['dob']",
                                      },
                                    ],
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "disabled-date": _vm.disabledDate,
                                      format: "DD/MM/YYYY",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [_c("hr")]
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [_c("h2", [_vm._v("Other Information")])]
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Emergency Name" } },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["emergency_name"],
                                        expression: "['emergency_name']",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 10, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Emergency Phone" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "custom-phone" },
                                    [
                                      _c("vue-phone-number-input", {
                                        attrs: {
                                          color: "#d9d9d9",
                                          "valid-color": "#d9d9d9",
                                          "error-color": "#d9d9d9",
                                          "default-country-code":
                                            _vm.emergencyPhoneIso,
                                          "preferred-countries":
                                            _vm.preferredCountries,
                                          "all-letters-characters": true,
                                        },
                                        on: {
                                          update:
                                            _vm.onEmergencyPhoneCountrySelect,
                                        },
                                        model: {
                                          value: _vm.emergency_phone,
                                          callback: function ($$v) {
                                            _vm.emergency_phone = $$v
                                          },
                                          expression: "emergency_phone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  !_vm.validEmergencyPhone
                                    ? _c("div", { staticClass: "has-error" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "ant-form-explain text-left",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Invalid emergency phone no.\n                  "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Address 1" } },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["address_1"],
                                        expression: "['address_1']",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Address 2" } },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["address_2"],
                                        expression: "['address_2']",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Town" } },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["town"],
                                        expression: "['town']",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Region" } },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["region"],
                                        expression: "['region']",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Postcode" } },
                                [
                                  _c("a-input", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: ["post_code"],
                                        expression: "['post_code']",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "ant-row ant-form-item" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "ant-form-item-label" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          attrs: {
                                            for: "region",
                                            title: "Region",
                                          },
                                        },
                                        [_vm._v("Country")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "ant-form-item-control-wrapper",
                                    },
                                    [
                                      _c("country-select", {
                                        attrs: {
                                          country: _vm.fields.country,
                                          "top-country": "IE",
                                          "class-name": "ant-input",
                                        },
                                        model: {
                                          value: _vm.fields.country,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.fields, "country", $$v)
                                          },
                                          expression: "fields.country",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [_c("hr")]
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [_c("h2", [_vm._v("Update Password")])]
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Current Password" } },
                                [
                                  _c("a-input", {
                                    attrs: { type: "password" },
                                    model: {
                                      value: _vm.current_password,
                                      callback: function ($$v) {
                                        _vm.current_password = $$v
                                      },
                                      expression: "current_password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "New Password" } },
                                [
                                  _c("a-input", {
                                    attrs: { type: "password" },
                                    model: {
                                      value: _vm.new_password,
                                      callback: function ($$v) {
                                        _vm.new_password = $$v
                                      },
                                      expression: "new_password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { attrs: { label: "Confirm Password" } },
                                [
                                  _c("a-input", {
                                    attrs: { type: "password" },
                                    model: {
                                      value: _vm.confirm_password,
                                      callback: function ($$v) {
                                        _vm.confirm_password = $$v
                                      },
                                      expression: "confirm_password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            {
                              attrs: { xl: 8, lg: 24, md: 24, sm: 24, xs: 24 },
                            },
                            [
                              _c(
                                "a-form-item",
                                { staticClass: "gx-text-left gx-mt-4" },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "primary",
                                        "html-type": "submit",
                                        loading: _vm.loading,
                                      },
                                      on: { click: _vm.handleForm },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Save Details\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }